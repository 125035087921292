@import '~antd/dist/antd.css';

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item-label > label {
  font-size: 12px;
}

.ant-form-item {
  margin-bottom: 2px !important;
  padding: 0px;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #BE638D ;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: #BE638D 33;
  box-shadow: 0 0 0 2px #BE638D 33;
}

.ant-input {
  background-color: #f1f1f1 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #f1f1f1 !important;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-form-large .ant-form-item-label > label {
  height: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #c7c7c7;
  border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  background-color: #f1f1f1 !important;
}

.ant-input-affix-wrapper {
  background-color: #f1f1f1 !important;
}

.ant-input {
  background-color: #f1f1f1 !important;
  text-align: center;
  border-radius: 0px;
}

.ant-form-vertical .ant-form-item {
  padding: 2px;
}

.suggestions {
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 999;
  list-style: none;
  border: 1px solid #BE638D ;
}

.suggestion-li {
  padding: 10px;
  text-align: center;
  margin: 0 !important;
}

.ant-btn-primary {
  color: #fff;
  background: #BE638D ;
  border-color: #BE638D ;
  width: 100%;
  height: 37px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ant-radio-checked::after {
  border: 1px solid #BE638D ;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #423B7D ;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #423B7D ;
}

.ant-radio-inner::after {
  background-color: #423B7D ;
}

.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 200;
  height: 140;
  background: #BE638D;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #BE638D;
}

.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 40px;
  width: 80px;
  margin: 0;
  padding: 0 15px;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  background: #616161;
  border: 1px solid #616161;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #616161;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #BE638D;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  border-color: #BE638D;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #fff;
  background: #616161;
  border-color: #1890ff;
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #616161;
  border-radius: 2px 0 0 2px;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #c7c7c7;
  border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  background-color: #f1f1f1 !important;
}

.ant-input-affix-wrapper {
  background-color: #f1f1f1 !important;
}

.suggestions {
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 999;
  list-style: none;
  border: 1px solid #BE638D ;
  max-height: 150px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.suggestion-item {
  padding: 10px;
  margin: 3px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.suggestion-item:hover {
  cursor: pointer;
  background-color: #BE638D  !important;
}

.ant-picker {
  background-color: #f1f1f1 !important;
  width: 100% !important;
  max-width: 100% !important;
}

.ant-btn {
  color: #000000d9;
  background: #f1f1f1;
  border-color: #BE638D ;
}

.ant-btn:hover,
.ant-btn:focus {
  color: #000000d9;
  background: #f1f1f1;
  border-color: #BE638D ;
  -webkit-box-shadow: #BE638D 33;
  box-shadow: 0 0 0 2px #BE638D 33;
}

.ant-dropdown {
  z-index: 800;
}

.ant-btn-dangerous {
  color: #ff4d4f !important;
  background: #fff !important;
  border-color: #ff4d4f !important;
}

.ant-btn-primary {
  width: auto;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0;
  }
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  text-align: justify;
  min-height: 50px;
}

.ant-spin-nested-loading {
  width: 100%;
}

.css-ltmxvp, [data-css-ltmxvp]  {
  width: 100%;
  height: 20px;
  position: relative;
  border: 1px solid #0f0f0f;
  border-radius: 10px;
  overflow: hidden;
}

.css-d9k6ir, [data-css-d9k6ir] {
  width: 9%;
  z-index: 8;
  height: 100%;
  top: 0;
  left: 0%;
  position: absolute;
  transition: width 0.6s ease-in-out, left 0.6s ease-in-out;
  border-radius: 0 0 0 0;
  color: write;
  text-align: center;
  font-size: 14px;
  -webkit-transition: width 0.6s ease-in-out, left 0.6s ease-in-out;
  -moz-transition: width 0.6s ease-in-out, left 0.6s ease-in-out;
}

.ant-checkbox-wrapper {
  box-sizing: border-box;
  background-color: #0d6ab1 ;
  border: 3px solid #0d6ab1 ;
  border-radius: 35px;
  margin: 0 auto;
  padding: 5px;
  width: 195px;
  height: 30px;
  color: #f1f1f1;
}

.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #f1f1f1;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  top: 0.2em;
  line-height: 1;
  white-space: nowrap;
  outline: none;
  cursor: pointer;
  text-align: center;
  padding-left: 1px;
  padding-top: 1px;

}

.ant-checkbox-inner {
  position: relative;
  top: 3px;
  left: 2;
  display: block;
  width: 15px;
  height: 15px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
  border-radius: 10px;

}

.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #c53e5900 ;
  border-radius: 2px;
  visibility: hidden;
  -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
          animation: antCheckboxEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  content: '';
}

.ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #BE638D;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}


.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #0d6ab1;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0d6ab1;
}

.MuiInputBase-root {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  height: 45px;
}

.WithStyles\(ForwardRef\(TableCell\)\)-body-884 {
  font-size: 13px;
  max-width: 10px;
  text-align: left;
  border-color: transparent;
  padding-left: 10px;
  padding-right: 0;
}


