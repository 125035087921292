.rs-picker-menu {
    z-index: 1001 !important;
  }
  @media only screen and (max-width: 360px) {
    .rs-picker-menu {
      top: 15% !important;
      left: 15% !important;
    }
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #fff !important;
  }

  .rs-picker-subtle .rs-btn:focus,
  .rs-picker-subtle .rs-picker-toggle:focus,
  .rs-picker-subtle .rs-btn.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-focus,
  .rs-picker-subtle .rs-btn:hover,
  .rs-picker-subtle .rs-picker-toggle:hover {
    color: #423B7D  !important;
    background: #423B7D  !important;
  }

  .rs-picker-subtle .rs-btn.active,
  .rs-picker-subtle .rs-picker-toggle.active {
    background: #423B7D  !important;
    color: #575757;
  }

  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #ffffff;
    border-color: #423B7D  !important;
    background-color: #423B7D  !important;
  }

  .rs-calendar-table-cell-in-range::before {
    background-color: #423B7D 1a !important;
  }

  .rs-picker-toolbar-option {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 8px 12px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    color: #423B7D  !important;
    font-weight: normal;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .rs-picker-toolbar-right-btn-ok {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 8px 12px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 0px !important;
    color: #fff;
    background-color: #423B7D  !important;
  }

  .rs-picker-toggle-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    font-weight: normal;
    color: #fff !important;
  }

  .rs-picker-menu.fade.in.placement-bottom-start.rs-picker-daterange-menu {
    top: 150px !important;
  }
